
import { BInput } from 'buetify/lib/components';
import BField from 'buetify/lib/components/form/field';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'field-variants-and-states-example',
	components: {
		BField,
		BInput
	}
});
