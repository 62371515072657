
import { BInput } from 'buetify/lib/components';
import BField from 'buetify/lib/components/form/field';
import { defineComponent } from 'vue';
import UserIcon from '../../../../../../components/icons/UserIcon';

export default defineComponent({
	name: 'input-sizes-example',
	components: {
		BField,
		BInput
	},
	setup() {
		return {
			UserIcon
		};
	}
});
