
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import { defineComponent } from 'vue';
import ApiView from '../../../../../components/apiView/ApiView.vue';
import ExampleView from '../../../../../components/exampleView/ExampleView.vue';
import { apis } from './apis';
import PasswordExample from './examples/PasswordExample.vue';
import PasswordExampleCode from '!!raw-loader!./examples/PasswordExample.vue';
import SimpleExample from './examples/SimpleExample.vue';
import SimpleCode from '!!raw-loader!./examples/SimpleExample.vue';
import SizeExample from './examples/SizeExample.vue';
import SizeExampleCode from '!!raw-loader!./examples/SizeExample.vue';
import ValidationExample from './examples/ValidationExample.vue';
import ValidationExampleCode from '!!raw-loader!./examples/ValidationExample.vue';
import VariantsAndStatesExample from './examples/VariantsAndStatesExample.vue';
import VariantsAndStatesExampleCode from '!!raw-loader!./examples/VariantsAndStatesExample.vue';

export default defineComponent({
	name: 'input-documentation',
	components: {
		ApiView,
		SimpleExample,
		SizeExample,
		ExampleView,
		VariantsAndStatesExample,
		PasswordExample,
		ValidationExample,
		BHorizontalDivider
	},
	setup() {
		return {
			apis,
			SimpleCode,
			VariantsAndStatesExampleCode,
			ValidationExampleCode,
			PasswordExampleCode,
			SizeExampleCode
		};
	}
});
